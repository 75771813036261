import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { LoginUser } from 'src/app/shared/models/users/login-user';
import { ConfigurationApp } from 'src/app/shared/models/configuration-app/configuration-app';
import { Member } from 'src/app/shared/models/members/member';
import { StorageService } from '../storage/storage-service.service';
import { Multicenter } from '../../models/configuration-app/multicenter';
import { ApiClientAccess } from '../../models/others/api-client-access';
import { ModuleAppTypeEnum } from '../../models/configuration-app/module-app-type-enum';
import { ModuleApp } from '../../models/configuration-app/module-app';
import { ModeKioskEnum } from '../../models/others/mode-kiosk.enum';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public apiClientAccess: ApiClientAccess = {
    nombre: environment.clienteAPI,
    password: environment.passClienteAPI,
  };

  public apiUrl: string = environment.apiBaseUrl;
  public idCenter: number = null;
  public token: string = null;
  public tokenFirebase: string = null;
  public user: LoginUser = null;
  public member: Member = null;
  public guestMode: boolean = false;
  public kioskMode: ModeKioskEnum = ModeKioskEnum.disabled;
  public myClubMode: boolean = false;
  public config: ConfigurationApp = null;
  public unreadNotifications: number = null;

  public defaultImage: string = null;
  public startPage: string = null;
  public multicenter: Multicenter = null;
  public onlineRegistrationEnabled: boolean = false;
  public entriesSellingEnabled: boolean = false;
  public guestModeEnabled: boolean = false;
  public logoURL: string = '/assets/images/deporweb.png';
  public loginBackgroundURL: string = null;
  public logoHomeWidth: number = 80;
  public language: string = 'en';

  constructor(
    private httpClient: HttpClient,
    private storage: StorageService
  ) {}

  public async init(): Promise<void> {
    return new Promise((resolve, rejects) => {
      Promise.all([
        this.httpClient.get('assets/images/noImageBase64.txt', { responseType: 'text' }).toPromise(),
        this.storage.get('token'),
        this.storage.get('user'),
        this.storage.get('tokenFirebase'),
        this.storage.get('idCenterMulticenter'),
        this.storage.get('guestMode'),
        this.storage.get('kioskMode'),
      ])
        .then((values) => {
          this.defaultImage = values[0];
          this.token = values[1];
          this.user = values[2];
          this.tokenFirebase = values[3];

          if (environment.idCenter) {
            this.idCenter = environment.idCenter;
          } else {
            this.idCenter = values[4] != null ? values[4] : null;
          }

          this.guestMode = values[5] ? values[5] : false;
          this.kioskMode = values[6] ? values[6] : ModeKioskEnum.disabled;

          resolve();
        })
        .catch(() => {
          rejects();
        });
    });
  }

  /**
   * Establece el token de la API
   *
   * @param token Token
   */
  public async setToken(token: string): Promise<void> {
    await this.storage.set('token', token).then(() => {
      this.token = token;
    });
  }

  /**
   * Elimina el token de la API
   *
   * @param token Token
   */
  public async removeToken(): Promise<void> {
    await this.storage.remove('token').then(() => {
      this.token = null;
    });
  }

  /**
   * Establece los datos de usuario identificado
   *
   * @param user Usuario
   */
  public async setUser(user: LoginUser): Promise<void> {
    await this.storage.set('user', user).then(() => {
      this.user = user;
    });
  }

  /**
   * Elimina los datos de usuario identificado
   *
   * @param user Usuario
   */
  public async removeUser(): Promise<void> {
    await Promise.all([
      this.storage.remove('user'),
      this.storage.remove('isPrincipal'),
      this.storage.remove('isTutored'),
      this.storage.remove('photo'),
    ]).then(() => {
      this.user = null;
      this.member = null;
    });
  }

  /**
   * Devuelve la foto del socio
   */
  public async getMemberPhoto(): Promise<{ photo: string; dateUpdate: Date }> {
    const value: { photo: string; dateUpdate: string } = await this.storage.get('photo');

    if (!value) {
      return null;
    }

    return {
      photo: value.photo,
      dateUpdate: new Date(value.dateUpdate),
    };
  }

  /**
   * Establece la foto del socio
   */
  public async setMemberPhoto(photo: string, dateUpdate: Date): Promise<void> {
    await this.storage.set('photo', { photo, dateUpdate }).then(() => {
      this.member.photo = photo;
    });
  }

  public async removeMemberPhoto(): Promise<void> {
    await this.storage.remove('photo');
  }

  /**
   * Activa el modo de invitado
   */
  public async enableGuestMode(): Promise<void> {
    await this.storage.set('guestMode', true).then(() => {
      this.guestMode = true;
    });
  }

  /**
   * Desactiva el modo de invitado
   */
  public async disableGuestMode(): Promise<void> {
    await this.storage.remove('guestMode').then(() => {
      this.guestMode = false;
    });
  }

  /**
   * Activa el modo kiosko 1 permite login, 2 no permite login
   */
  public async enableKioskMode(kioskMode: ModeKioskEnum): Promise<void> {
    await this.storage.set('kioskMode', kioskMode).then(() => {
      this.kioskMode = kioskMode;
    });
  }

  /**
   * Desactiva el modo kiosko
   */
  public async disableKioskMode(): Promise<void> {
    await this.storage.remove('kioskMode').then(() => {
      this.kioskMode = ModeKioskEnum.disabled;
    });
  }

  /**
   * Establece el token de Firebase
   *
   * @param token Token
   */
  public async setTokenFirebase(token: string): Promise<void> {
    await this.storage.set('tokenFirebase', token).then(() => {
      this.tokenFirebase = token;
    });
  }

  /**
   * Establece el centro del socio de un multicentro
   *
   * @param idCenter Identificador de centro
   */
  public async setCenterMulticenter(idCenter: number): Promise<void> {
    await this.storage.set('idCenterMulticenter', idCenter).then(() => {
      this.idCenter = idCenter;
    });
  }

  /**
   * Elimina el centro del socio de un multicentro
   */
  public async removeCenterMulticenter(): Promise<void> {
    await this.storage.remove('idCenterMulticenter').then(() => {
      if (environment.idAppMulticentro) {
        this.idCenter = null;
      } else {
        this.idCenter = environment.idCenter;
      }
    });
  }

  /**
   * Obtiene si el socio es principal
   */
  public async getIsPrincipal(): Promise<boolean> {
    return await this.storage.get('isPrincipal');
  }

  /**
   * Establece si el socio es principal
   */
  public async setIsPrincipal(isPrincipal: boolean): Promise<void> {
    await this.storage.set('isPrincipal', isPrincipal).then(() => {
      this.member.isPrincipal = isPrincipal;
    });
  }

  /**
   * Obtiene si el socio es tutorizado
   */
  public async getIsTutored(): Promise<boolean> {
    return await this.storage.get('isTutored');
  }

  /**
   * Establece si el socio es tutorizado
   */
  public async setIsTutored(isTutored: boolean): Promise<void> {
    await this.storage.set('isTutored', isTutored).then(() => {
      this.member.isTutored = isTutored;
    });
  }

  public getModule(moduleType: ModuleAppTypeEnum): ModuleApp {
    let module: ModuleApp = this.config.modulesHome.find((m) => m.id === moduleType);

    if (!module) {
      module = this.config.modulesMenu.find((m) => m.id === moduleType);
    }

    return module;
  }

  public getModuleTitle(
    moduleType: ModuleAppTypeEnum,
    filters?: {
      idCategory?: number;
      activitiesGroups?: string;
      collectiveZonesIds?: string;
    }
  ): string {
    let module: ModuleApp = this.config.modulesHome.find((m) => m.is(moduleType, filters));

    if (!module) {
      module = this.config.modulesMenu.find((m) => m.is(moduleType, filters));
    }

    if (module) {
      return module.text;
    }

    return null;
  }
}

/**
 * Función que inicializa el servicio de datos globales garantizando que los
 * datos esten cargados para usarse sincronamente al inicio.
 *
 * @param globalService GlobalService
 */
export const initGlobalService = (globalService: GlobalService) => {
  return (): Promise<void> => globalService.init();
};
