import { AppPaymentMethodEnum } from 'src/app/shared/models/payments/app-payment-method.enum';
import { AppPaymentMechanismEnum } from 'src/app/shared/models/payments/app-payment-mechanism.enum';

export class PaymentMechanism {
  public appPaymentMechanism: AppPaymentMechanismEnum;
  public appPaymentMethod: AppPaymentMethodEnum;

  public constructor(fields?: {
    appPaymentMechanism: AppPaymentMechanismEnum;
    appPaymentMethod: AppPaymentMethodEnum;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}
