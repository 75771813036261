import { MemberGroupBase } from './member-group';

export class MemberBase {
  public id: number;
  public name: string;
  public surname: string;
  public photo: string;
  public memberNo: number;
  public identificationDocument: string;

  public constructor(fields?: {
    id: number;
    name: string;
    surname: string;
    photo: string;
    memberNo: number;
    identificationDocument: string;
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}

export class Member extends MemberBase {
  public isMember: boolean;
  public isEmployee: boolean;
  public group: MemberGroupBase;
  public isPrincipal: boolean;
  public isTutored: boolean;
  public dateUploadPhoto: Date;

  public constructor(fields?: {
    id: number;
    name: string;
    surname: string;
    photo: string;
    memberNo: number;
    identificationDocument: string;
    isMember: boolean;
    isEmployee: boolean;
    group: MemberGroupBase;
    isPrincipal: boolean;
    isTutored: boolean;
    dateUploadPhoto: Date;
  }) {
    super();

    if (fields) {
      Object.assign(this, fields);
    }
  }
}
