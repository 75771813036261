import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Subscription, fromEvent } from 'rxjs';
// SERVICIOS
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { CrashesMonitorService } from 'src/app/shared/services/crashes-monitor/crashes-monitor.service';
import { GlobalService } from 'src/app/shared/services/global/global.service';
import { InjectorService } from 'src/app/shared/services/injector/inyector.service';
import { InitAppService } from 'src/app/core/services/init-app/init-app.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
// MODELOS
import { environment } from 'src/environments/environment';
import { ModeKioskEnum } from '../../models/others/mode-kiosk.enum';

const AUTO_LOGOUT_MILLISECONDS: number = 40000;
const CHECK_INTERVAL_MILLISECONDS: number = 5000;

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  private lastAction: number = 0;
  private interval: any = null;
  private subscription: Subscription;

  constructor(
    private navController: NavController,
    private authService: AuthService,
    private alertService: AlertService,
    private languageService: LanguageService,
    private crashesMonitorService: CrashesMonitorService,
    private globalService: GlobalService,
    private notificationsService: NotificationsService
  ) {}

  public doLogOut(): void {
    if (this.isAutoLogoutActive()) {
      this.stopAutoLogout();
    }

    this.alertService.presentLoading();

    this.authService
      .doPostClientData(this.globalService.apiClientAccess)
      .subscribe({
        next: (resp) => {
          const promises: Promise<any>[] = [];
          promises.push(this.globalService.setToken(resp));
          promises.push(this.globalService.removeUser());
          promises.push(this.globalService.disableGuestMode());

          if (this.globalService.kioskMode === ModeKioskEnum.disabled) {
            promises.push(this.globalService.removeCenterMulticenter());
          }

          Promise.all(promises)
            .then(async () => {
              if (environment.idAppMulticentro) {
                await InjectorService.injector.get(InitAppService).initializeApp();
              }

              this.notificationsService.disableNotificationsService();
              this.alertService.presentToastWithIcon(
                this.languageService.getText('LOGOUT.MSG_LOGOUT_SUCCESS'),
                null,
                'top',
                null
              );
              this.navController.navigateRoot([this.globalService.startPage]);
            })
            .then(() => {
              if (this.globalService.myClubMode) {
                this.globalService.config = null;
                this.globalService.logoURL = '/assets/images/deporweb.png';
                this.globalService.loginBackgroundURL = null;
                this.globalService.logoHomeWidth = 80;
                InjectorService.injector.get(InitAppService).removeStyleConfiguration();
              }

              this.alertService.dismissLoading();
            })
            .catch(() => {
              this.alertService.dismissLoading();
            });
        },
        error: (err) => {
          this.alertService.dismissLoading();

          if (err.code !== 401) {
            this.crashesMonitorService.reportAndShowException({
              className: 'LogoutService',
              methodName: 'doLogOut',
              errStr: err,
              msg: this.languageService.getText('GLOBAL.ERROR_GENERIC_MSG'),
            });
          } else {
            this.alertService.presentToastWithIcon(
              this.languageService.getText('LOGOUT.MSG_LOGOUT_FAIL'),
              null,
              'top',
              'close-circle-outline'
            );
          }
        }
      });
  }

  public isAutoLogoutActive(): boolean {
    return this.interval !== null;
  }

  public initAutoLogout(): void {
    this.initListeners();
    this.initInterval();
    this.lastAction = Date.now();
  }

  public stopAutoLogout(): void {
    this.clearInterval();
    this.clearListeners();
  }

  private initListeners(): void {
    this.subscription = fromEvent(document, 'click').subscribe(() => {
      this.lastAction = Date.now();
    });
  }

  private initInterval(): void {
    this.interval = setInterval(() => {
      this.check();
    }, CHECK_INTERVAL_MILLISECONDS);
  }

  private clearListeners(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private clearInterval(): void {
    clearInterval(this.interval);
    this.interval = null;
  }

  private async check(): Promise<void> {
    const now = Date.now();
    const timeleft = this.lastAction + AUTO_LOGOUT_MILLISECONDS;
    const diff = timeleft - now;
    console.log(diff);
    if (diff <= 0) {
      this.doLogOut();
    }
    else if (diff <= 5000) {
      this.alertService.presentToastWithIcon(
        this.languageService.getText('LOGOUT.MSG_AUTOLOGOUT_WARNING').replace('@t', 5),
        null,
        'top',
        'time',
        4000
      );
    }
    else if (diff <= 10000) {
      this.alertService.presentToastWithIcon(
        this.languageService.getText('LOGOUT.MSG_AUTOLOGOUT_WARNING').replace('@t', 10),
        null,
        'top',
        'time',
        4000
      );
    }
  }
}
