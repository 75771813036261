import { Platform, NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import * as moment from 'moment';

import { environment } from 'src/environments/environment';

// Services
import { GlobalService } from 'src/app/shared/services/global/global.service';
import { CrashesMonitorService } from 'src/app/shared/services/crashes-monitor/crashes-monitor.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { MemberService } from 'src/app/account/services/member/member.service';
import { ConfigService } from './../config/config.service';
import { LogoutService } from 'src/app/shared/services/logout/logout.service';
import { JwtHelper } from 'src/app/shared/helpers/JWTHelper';

// Models
import { ConfigurationApp } from 'src/app/shared/models/configuration-app/configuration-app';
import { LoginUser } from 'src/app/shared/models/users/login-user';
import { Member } from 'src/app/shared/models/members/member';
import { Multicenter } from 'src/app/shared/models/configuration-app/multicenter';
import { ModeKioskEnum } from 'src/app/shared/models/others/mode-kiosk.enum';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class InitAppService {
  constructor(
    private platform: Platform,
    private navController: NavController,
    private window: Window,
    private statusBar: StatusBar,
    private globalService: GlobalService,
    private crashesMonitorService: CrashesMonitorService,
    private authService: AuthService,
    private notificationsService: NotificationsService,
    private memberService: MemberService,
    private configService: ConfigService,
    private logoutService: LogoutService
  ) {}

  public async initializeApp(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.platform.ready().then(async () => {
        let authOK: boolean = false;

        await this.checkInitModeParameters();

        if (this.globalService.kioskMode === ModeKioskEnum.disabled && !environment.idAppMulticentro && !environment.idCenter) {
          this.globalService.myClubMode = true;
        }

        this.autologin()
          .then(() => {
            return this.checkTokenExpiration();
          })
          .then(() => {
            return this.checkUserAuthenticathed();
          })
          .then((res: boolean) => {
            authOK = res;
            return this.loadAppConfiguration();
          })
          .then(async () => {
            let onlineRegistrationEnabled: boolean = false;

            if (this.globalService.idCenter !== null && this.globalService.config) {
              if (this.globalService.config.linkOnlineRegistration) {
                if (this.platform.is('android')) {
                  onlineRegistrationEnabled = this.globalService.config.onlineRegistrationAndroidEnabled;
                } else if (this.platform.is('ios')) {
                  onlineRegistrationEnabled = this.globalService.config.onlineRegistrationIOSEnabled;
                } else {
                  onlineRegistrationEnabled = this.globalService.config.onlineRegistrationAndroidEnabled;
                }
              }

              if (this.globalService.myClubMode) {
                this.globalService.startPage = '/public/login';
              } else if (this.globalService.kioskMode !== ModeKioskEnum.disabled) {
                if (
                  this.globalService.config.guestModeEnabled ||
                  onlineRegistrationEnabled ||
                  this.globalService.config.entriesSellingEnabled ||
                  this.globalService.config.loginKioskDeviceEnabled ||
                  this.globalService.config.loginKioskQREnabled
                ) {
                  this.globalService.startPage = '/public/start-kiosk';
                } else {
                  this.globalService.startPage = '/public/login';
                }
              } else {
                if (
                  this.globalService.config.guestModeEnabled ||
                  onlineRegistrationEnabled ||
                  this.globalService.config.entriesSellingEnabled
                ) {
                  this.globalService.startPage = '/public/start';
                } else {
                  this.globalService.startPage = '/public/login';
                }
              }

              this.globalService.onlineRegistrationEnabled = onlineRegistrationEnabled;
              this.globalService.entriesSellingEnabled = this.globalService.config.entriesSellingEnabled;
              this.globalService.guestModeEnabled = this.globalService.config.guestModeEnabled;
              this.globalService.logoURL = this.globalService.config.logoURL;
              this.globalService.loginBackgroundURL = this.globalService.config.loginBackgroundURL;
              this.globalService.logoHomeWidth = this.globalService.config.logoHomeWidth;
            } else if (environment.idAppMulticentro) {
              await this.loadMulticenter();

              if (this.platform.is('android')) {
                onlineRegistrationEnabled = this.globalService.multicenter.onlineRegistrationAndroidEnabled;
              } else if (this.platform.is('ios')) {
                onlineRegistrationEnabled = this.globalService.multicenter.onlineRegistrationIOSEnabled;
              } else {
                onlineRegistrationEnabled = this.globalService.multicenter.onlineRegistrationAndroidEnabled;
              }

              if (
                this.globalService.multicenter.guestModeEnabled ||
                onlineRegistrationEnabled ||
                this.globalService.multicenter.entriesSellingEnabled
              ) {
                this.globalService.startPage = '/public/start';
              } else {
                this.globalService.startPage = '/public/login';
              }

              this.globalService.onlineRegistrationEnabled = onlineRegistrationEnabled;
              this.globalService.entriesSellingEnabled = this.globalService.multicenter.entriesSellingEnabled;
              this.globalService.guestModeEnabled = this.globalService.multicenter.guestModeEnabled;
              this.globalService.logoURL = this.globalService.multicenter.logoURL;
              this.globalService.loginBackgroundURL = this.globalService.multicenter.loginBackgroundURL;
              this.globalService.logoHomeWidth = this.globalService.multicenter.logoHomeWidth;
            } else {
              this.globalService.startPage = '/public/login';
            }

            if (this.globalService.config && (authOK || this.globalService.guestMode)) {
              // Eliminar token, modo quiosco y centro de la URL
              const params = new URLSearchParams(this.window.location.search);
              params.delete('token');
              params.delete('kiosk');
              params.delete('center');

              const urlQueryString: string = params.toString();

              const pathOrig: string = this.window.location.pathname;

              this.navController.navigateRoot('/', { replaceUrl: true }).then(() => {
                if (pathOrig !== '/' && pathOrig !== '/noconnection') {
                  this.navController.navigateForward(pathOrig + (urlQueryString ? '?' : '') + urlQueryString).then(() => {
                    resolve();
                  });
                } else {
                  resolve();
                }
              });
            } else {
              const params = new URLSearchParams(this.window.location.search);
              const urlQueryString: string = params.toString();
              const pathOrig: string = this.window.location.pathname;

              this.navController
                .navigateRoot(this.globalService.startPage, {
                  replaceUrl: true,
                })
                .then(() => {
                  if (pathOrig === '/payments/payment-result') {
                    this.navController.navigateForward(pathOrig + (urlQueryString ? '?' : '') + urlQueryString).then(() => {
                      resolve();
                    });
                  } else {
                    resolve();
                  }
                });
            }
          })
          .catch((err) => {
            this.crashesMonitorService.reportException('InitAppService', 'initializeApp', JSON.stringify(err));
            this.navController.navigateRoot('/noconnection').then(() => {
              resolve();
            });
          });
      });
    });
  }

  public async loadAppConfiguration(): Promise<boolean> {
    return new Promise((resolve, rejects) => {
      if (this.globalService.idCenter !== null) {
        return lastValueFrom(this.configService.getAppConfig(this.globalService.idCenter))
          .then((config: ConfigurationApp) => {
            this.globalService.config = config;
            return this.loadStyleConfiguration();
          })
          .then(() => {
            if (
              this.globalService.user &&
              this.globalService.kioskMode !== ModeKioskEnum.disabled &&
              !this.logoutService.isAutoLogoutActive() &&
              environment.production
            ) {
              this.logoutService.initAutoLogout();
            }
            resolve(true);
          })
          .catch((err) => {
            rejects(err);
          });
      } else {
        resolve(false);
      }
    });
  }

  public async loadMember(): Promise<boolean> {
    if (!this.globalService.user || !this.globalService.user.idMember) {
      return Promise.resolve(false);
    }

    return new Promise((resolve, rejects) => {
      Promise.all([
        lastValueFrom(this.memberService.getMember(this.globalService.user.idMember)),
        this.globalService.getMemberPhoto(),
        this.globalService.getIsPrincipal(),
        this.globalService.getIsTutored(),
        this.notificationsService.enableNotificationsService(),
      ])
        .then(async (values: [Member, any, boolean, boolean, void]) => {
          this.globalService.member = values[0];

          const cachedPhoto: { photo: string; dateUpdate: Date } = values[1];

          if (
            this.globalService.member.dateUploadPhoto &&
            (!cachedPhoto || this.globalService.member.dateUploadPhoto > cachedPhoto.dateUpdate)
          ) {
            lastValueFrom(this.memberService.getMemberImage(this.globalService.member.id))
              .then((photo: any) => {
                this.globalService.setMemberPhoto(photo, this.globalService.member.dateUploadPhoto);
              })
              .catch((err) => {
                this.crashesMonitorService.reportException('InitAppService', 'loadMember - getMemberImage', JSON.stringify(err));
              });
          } else if (!this.globalService.member.dateUploadPhoto && cachedPhoto) {
            await this.globalService.removeMemberPhoto();
          } else if (cachedPhoto) {
            this.globalService.member.photo = cachedPhoto.photo;
          }

          if (values[2] !== null) {
            this.globalService.member.isPrincipal = values[2];
          } else {
            this.globalService.setIsPrincipal(this.globalService.member.isPrincipal);
          }

          if (values[3] !== null) {
            this.globalService.member.isTutored = values[3];
          } else {
            this.globalService.setIsTutored(this.globalService.member.isTutored);
          }

          resolve(true);
        })
        .catch((err) => {
          rejects(err);
        });
    });
  }

  public removeStyleConfiguration(): void {
    const style: HTMLElement = document.getElementById('configStyle');

    if (style) {
      document.head.removeChild(style);
    }
  }

  private async checkUserAuthenticathed(): Promise<boolean> {
    return new Promise((resolve, rejects) => {
      if (!this.globalService.token || !this.globalService.user) {
        lastValueFrom(this.authService.doPostClientData(this.globalService.apiClientAccess))
          .then((token: string) => {
            return this.globalService.setToken(token);
          })
          .then(() => {
            resolve(false);
          })
          .catch((err) => {
            this.crashesMonitorService.reportException('InitAppService', 'checkUserAuthenticathed (1)', JSON.stringify(err));
            rejects(err);
          });
      } else {
        this.loadMember()
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            this.crashesMonitorService.reportException('InitAppService', 'checkUserAuthenticathed (2)', JSON.stringify(err));
            rejects(err);
          });
      }
    });
  }

  private async checkInitModeParameters(): Promise<void> {
    if (!this.window.location.search) {
      return Promise.resolve();
    }

    const params = new URLSearchParams(this.window.location.search);

    if (params.has('kiosk')) {
      const kioskMode: number = parseInt(params.get('kiosk'), 10);

      if (kioskMode === ModeKioskEnum.disabled) {
        await this.globalService.disableKioskMode();
      } else {
        await this.globalService.enableKioskMode(kioskMode);
      }
    }

    if (!environment.idCenter && params.has('center')) {
      const idCenter: number = parseInt(params.get('center'), 10);
      this.globalService.setCenterMulticenter(idCenter);
    }
  }

  /**
   * Comprueba caducidad del token de acceso a la API y si va a caducar lo refresca
   */
  private async checkTokenExpiration(): Promise<void> {
    if (!this.globalService.token) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      const jwtHelper: JwtHelper = new JwtHelper();

      const decodedToken: any = jwtHelper.decodeToken(this.globalService.token);

      const dateToday = new Date(moment().format());

      const dateExpire: Date = new Date(moment(0).format());
      dateExpire.setUTCSeconds(decodedToken.exp); // Establece fecha de caducidad

      // Token caducado, lo eliminamos del storage
      if (dateToday > dateExpire) {
        Promise.all([this.globalService.removeToken(), this.globalService.removeUser(), this.globalService.removeCenterMulticenter()]).then(
          () => {
            resolve();
          }
        );
        return;
      }

      dateExpire.setDate(dateExpire.getDate() - 15); // Quitamos 15 dias

      // Si quedan mas de 15 dias para que caduque el token no hacemos nada
      if (dateToday < dateExpire) {
        resolve();
        return;
      }

      // Si quedan 15 dias o menos refrescamos el token
      this.authService.refreshToken().subscribe({
        next: (token: string) => {
          this.globalService.setToken(token).then(() => {
            resolve();
          });
        },
        error: (err) => {
          Promise.all([
            this.globalService.removeToken(),
            this.globalService.removeUser(),
            this.globalService.removeCenterMulticenter(),
          ]).then(() => {
            this.crashesMonitorService.reportException('InitAppService', 'checkTokenExpiration', JSON.stringify(err));

            resolve();
          });
        },
      });
    });
  }

  /**
   * Carga la configuración del estilo
   */
  private loadStyleConfiguration(): Promise<void> {
    return new Promise((resolve) => {
      const styleHTML: any = {};

      if (this.globalService.config.colorPrimaryBase) {
        styleHTML['--ion-color-primary'] = this.globalService.config.colorPrimaryBase;
      }

      if (this.globalService.config.colorPrimaryBaseRGB) {
        styleHTML['--ion-color-primary-rgb'] = this.globalService.config.colorPrimaryBaseRGB;
      }

      if (this.globalService.config.colorPrimaryContrast) {
        styleHTML['--ion-color-primary-contrast'] = this.globalService.config.colorPrimaryContrast;
      }

      if (this.globalService.config.colorPrimaryContrastRGB) {
        styleHTML['--ion-color-primary-contrast-rgb'] = this.globalService.config.colorPrimaryContrastRGB;
      }

      if (this.globalService.config.colorPrimaryShade) {
        styleHTML['--ion-color-primary-shade'] = this.globalService.config.colorPrimaryShade;
      }

      if (this.globalService.config.colorPrimaryTint) {
        styleHTML['--ion-color-primary-tint'] = this.globalService.config.colorPrimaryTint;
      }

      if (this.globalService.config.colorBackgroundSolidBase) {
        styleHTML['--ion-color-background-solid'] = this.globalService.config.colorBackgroundSolidBase;
      }

      if (this.globalService.config.colorBackgroundSolidBaseRGB) {
        styleHTML['--ion-color-background-solid-rgb'] = this.globalService.config.colorBackgroundSolidBaseRGB;
      }

      if (this.globalService.config.colorBackgroundSolidContrast) {
        styleHTML['--ion-color-background-solid-contrast'] = this.globalService.config.colorBackgroundSolidContrast;
      }

      if (this.globalService.config.colorBackgroundSolidContrastRGB) {
        styleHTML['--ion-color-background-solid-contrast-rgb'] = this.globalService.config.colorBackgroundSolidContrastRGB;
      }

      if (this.globalService.config.colorBackgroundSolidShade) {
        styleHTML['--ion-color-background-solid-shade'] = this.globalService.config.colorBackgroundSolidShade;
      }

      if (this.globalService.config.colorBackgroundSolidTint) {
        styleHTML['--ion-color-background-solid-tint'] = this.globalService.config.colorBackgroundSolidTint;
      }

      if (this.globalService.config.colorHeaderBase) {
        styleHTML['--ion-color-header'] = this.globalService.config.colorHeaderBase;
      }

      if (this.globalService.config.colorHeaderBaseRGB) {
        styleHTML['--ion-color-header-rgb'] = this.globalService.config.colorHeaderBaseRGB;
      }

      if (this.globalService.config.colorHeaderContrast) {
        styleHTML['--ion-color-header-contrast'] = this.globalService.config.colorHeaderContrast;
      }

      if (this.globalService.config.colorHeaderContrastRGB) {
        styleHTML['--ion-color-header-contrast-rgb'] = this.globalService.config.colorHeaderContrastRGB;
      }

      if (this.globalService.config.colorHeaderShade) {
        styleHTML['--ion-color-header-shade'] = this.globalService.config.colorHeaderShade;
      }

      if (this.globalService.config.colorHeaderTint) {
        styleHTML['--ion-color-header-tint'] = this.globalService.config.colorHeaderTint;
      }

      if (this.globalService.config.colorButtonMainBase) {
        styleHTML['--ion-color-button-main'] = this.globalService.config.colorButtonMainBase;
      }

      if (this.globalService.config.colorButtonMainBaseRGB) {
        styleHTML['--ion-color-button-main-rgb'] = this.globalService.config.colorButtonMainBaseRGB;
      }

      if (this.globalService.config.colorButtonMainContrast) {
        styleHTML['--ion-color-button-main-contrast'] = this.globalService.config.colorButtonMainContrast;
      }

      if (this.globalService.config.colorButtonMainContrastRGB) {
        styleHTML['--ion-color-button-main-contrast-rgb'] = this.globalService.config.colorButtonMainContrastRGB;
      }

      if (this.globalService.config.colorButtonMainShade) {
        styleHTML['--ion-color-button-main-shade'] = this.globalService.config.colorButtonMainShade;
      }

      if (this.globalService.config.colorButtonMainTint) {
        styleHTML['--ion-color-button-main-tint'] = this.globalService.config.colorButtonMainTint;
      }

      if (this.globalService.config.colorListBackgroundBase) {
        styleHTML['--ion-color-list-background'] = this.globalService.config.colorListBackgroundBase;
      }

      if (this.globalService.config.colorListBackgroundBaseRGB) {
        styleHTML['--ion-color-list-background-rgb'] = this.globalService.config.colorListBackgroundBaseRGB;
      }

      if (this.globalService.config.colorListBackgroundContrast) {
        styleHTML['--ion-color-list-background-contrast'] = this.globalService.config.colorListBackgroundContrast;
      }

      if (this.globalService.config.colorListBackgroundContrastRGB) {
        styleHTML['--ion-color-list-background-contrast-rgb'] = this.globalService.config.colorListBackgroundContrastRGB;
      }

      if (this.globalService.config.colorListBackgroundShade) {
        styleHTML['--ion-color-list-background-shade'] = this.globalService.config.colorListBackgroundShade;
      }

      if (this.globalService.config.colorListBackgroundTint) {
        styleHTML['--ion-color-list-background-tint'] = this.globalService.config.colorListBackgroundTint;
      }

      if (this.globalService.config.colorButtonPositiveBase) {
        styleHTML['--ion-color-button-positive'] = this.globalService.config.colorButtonPositiveBase;
      }

      if (this.globalService.config.colorButtonPositiveBaseRGB) {
        styleHTML['--ion-color-button-positive-rgb'] = this.globalService.config.colorButtonPositiveBaseRGB;
      }

      if (this.globalService.config.colorButtonPositiveContrast) {
        styleHTML['--ion-color-button-positive-contrast'] = this.globalService.config.colorButtonPositiveContrast;
      }

      if (this.globalService.config.colorButtonPositiveContrastRGB) {
        styleHTML['--ion-color-button-positive-contrast-rgb'] = this.globalService.config.colorButtonPositiveContrastRGB;
      }

      if (this.globalService.config.colorButtonPositiveShade) {
        styleHTML['--ion-color-button-positive-shade'] = this.globalService.config.colorButtonPositiveShade;
      }

      if (this.globalService.config.colorButtonPositiveTint) {
        styleHTML['--ion-color-button-positive-tint'] = this.globalService.config.colorButtonPositiveTint;
      }

      if (this.globalService.config.colorButtonNegativeBase) {
        styleHTML['--ion-color-button-negative'] = this.globalService.config.colorButtonNegativeBase;
      }

      if (this.globalService.config.colorButtonNegativeBaseRGB) {
        styleHTML['--ion-color-button-negative-rgb'] = this.globalService.config.colorButtonNegativeBaseRGB;
      }

      if (this.globalService.config.colorButtonNegativeContrast) {
        styleHTML['--ion-color-button-negative-contrast'] = this.globalService.config.colorButtonNegativeContrast;
      }

      if (this.globalService.config.colorButtonNegativeContrastRGB) {
        styleHTML['--ion-color-button-negative-contrast-rgb'] = this.globalService.config.colorButtonNegativeContrastRGB;
      }

      if (this.globalService.config.colorButtonNegativeShade) {
        styleHTML['--ion-color-button-negative-shade'] = this.globalService.config.colorButtonNegativeShade;
      }

      if (this.globalService.config.colorButtonNegativeTint) {
        styleHTML['--ion-color-button-negative-tint'] = this.globalService.config.colorButtonNegativeTint;
      }

      if (this.globalService.config.colorText) {
        styleHTML['--own-text-color'] = this.globalService.config.colorText;
      }

      if (this.globalService.config.fontFamily) {
        styleHTML['--own-font-family'] = this.globalService.config.fontFamily;
      }

      if (this.globalService.config.colorBackground) {
        styleHTML['--own-color-background'] = this.globalService.config.colorBackground;
      }

      if (this.globalService.config.colorError) {
        styleHTML['--own-color-error'] = this.globalService.config.colorError;
      }

      if (this.globalService.config.fontSize) {
        styleHTML['--own-font-size'] = this.globalService.config.fontSize;
      }

      if (this.globalService.config.colorBorder) {
        styleHTML['--own-color-border'] = this.globalService.config.colorBorder;
      }

      if (this.globalService.config.colorListBorder) {
        styleHTML['--own-color-list-border'] = this.globalService.config.colorListBorder;
      }

      if (this.globalService.config.textColorListTitle) {
        styleHTML['--own-text-color-list-title'] = this.globalService.config.textColorListTitle;
      }

      if (this.globalService.config.textColorListGroupTitle) {
        styleHTML['--own-text-color-list-group-title'] = this.globalService.config.textColorListGroupTitle;
      }

      if (this.globalService.config.textColorPageDetailTitle) {
        styleHTML['--own-text-color-page-detail-title'] = this.globalService.config.textColorPageDetailTitle;
      }

      if (this.globalService.config.colorDatatableBorder) {
        styleHTML['--own-color-datatable-border'] = this.globalService.config.colorDatatableBorder;
      }

      if (this.globalService.config.colorDatatableHeaderBackground) {
        styleHTML['--own-color-datatable-header-background'] = this.globalService.config.colorDatatableHeaderBackground;
      }

      if (this.globalService.config.colorDatatableHeaderText) {
        styleHTML['--own-color-datatable-header-color'] = this.globalService.config.colorDatatableHeaderText;
      }

      if (this.globalService.config.colorBackgroundBadge) {
        styleHTML['--own-color-background-badge'] = this.globalService.config.colorBackgroundBadge;
      }

      if (this.globalService.config.colorFontBadge) {
        styleHTML['--own-color-font-badge'] = this.globalService.config.colorFontBadge;
      }

      const styleString: string =
        ':root {' +
        Object.entries(styleHTML)
          .map(([k, v]) => {
            return `${k}:${v}`;
          })
          .join(';');

      const style: HTMLStyleElement = document.createElement('style');
      style.id = 'configStyle';
      style.onload = (): void => {
        resolve();
      };
      style.innerHTML = styleString;
      document.head.appendChild(style);

      if (this.platform.is('cordova')) {
        this.loadStatusBarConfiguration();
      }
    });
  }

  /**
   * Carga la configuración de la barra de estado conforme a los colores del centro
   */
  private loadStatusBarConfiguration(): void {
    if (this.globalService.config.colorHeaderBase) {
      this.statusBar.backgroundColorByHexString(this.globalService.config.colorHeaderBase);
    } else {
      const baseColor: string = window.getComputedStyle(document.body).getPropertyValue('--ion-color-header').trim();
      this.statusBar.backgroundColorByHexString(baseColor);
    }

    if (
      (this.globalService.config.colorHeaderContrast && this.globalService.config.colorHeaderContrast.toUpperCase() === '#FFFFFF') ||
      window.getComputedStyle(document.body).getPropertyValue('--ion-color-header-contrast').trim().toUpperCase() === '#FFFFFF'
    ) {
      this.statusBar.styleLightContent();
    } else {
      this.statusBar.styleDefault();
    }
  }

  //#region Autologin

  private async autologin(): Promise<void> {
    let token: string = null;

    await this.getAutologinToken().then((tokenRes: string) => {
      token = tokenRes;
    });

    if (!token) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      this.globalService
        .setToken(token)
        .then(() => {
          return lastValueFrom(this.authService.getMemberInformationToken());
        })
        .then((user: LoginUser) => {
          return Promise.all([this.globalService.setUser(user), this.globalService.setCenterMulticenter(user.idCenter)]);
        })
        .then(() => {
          resolve();
        })
        .catch(() => {
          Promise.all([
            this.globalService.removeToken(),
            this.globalService.removeUser(),
            this.globalService.removeCenterMulticenter(),
          ]).then(() => {
            resolve();
          });
        });
    });
  }

  private async getAutologinToken(): Promise<string> {
    if (!this.window.location.search) {
      return Promise.resolve(null);
    }

    return new Promise((resolve) => {
      const paramsAutologin = new URLSearchParams(this.window.location.search);

      if (paramsAutologin.get('token')) {
        resolve(paramsAutologin.get('token'));
      }

      resolve(null);
    });
  }

  //#endregion

  //#region Multicenter

  private loadMulticenter(): Promise<void> {
    return new Promise<void>((resolve, rejects) => {
      this.configService.getCentersMulticenter(environment.idAppMulticentro).subscribe({
        next: async (multicenter: Multicenter) => {
          this.globalService.multicenter = multicenter;
          await this.loadMulticenterStyle();
          resolve();
        },
        error: (err) => {
          this.crashesMonitorService.reportException('InitAppService', 'loadMultiCenter', JSON.stringify(err));
          rejects(err);
        },
      });
    });
  }

  private loadMulticenterStyle(): Promise<void> {
    return new Promise<void>((resolve) => {
      const styleHTML: any = {};

      if (this.globalService.multicenter.backgroundHeaderColor) {
        styleHTML['--ion-color-primary'] = this.globalService.multicenter.backgroundHeaderColor;
        styleHTML['--ion-color-primary-shade'] = this.globalService.multicenter.backgroundHeaderColor;
      }

      if (this.globalService.multicenter.fontHeaderColor) {
        styleHTML['--ion-color-primary-contrast'] = this.globalService.multicenter.fontHeaderColor;
      }

      const styleString: string =
        ':root {' +
        Object.entries(styleHTML)
          .map(([k, v]) => {
            return `${k}:${v}`;
          })
          .join(';');

      const style: HTMLStyleElement = document.createElement('style');
      style.innerHTML = styleString;
      style.onload = (): void => {
        resolve();
      };
      document.head.appendChild(style);

      if (this.platform.is('cordova')) {
        this.loadMulticenterStatusBarConfiguration();
      }
    });
  }

  /**
   * Carga la configuración de la barra de estado conforme a los colores del multicentro
   */
  private loadMulticenterStatusBarConfiguration(): void {
    if (this.globalService.multicenter.backgroundHeaderColor) {
      this.statusBar.backgroundColorByHexString(this.globalService.multicenter.backgroundHeaderColor);
    } else {
      const baseColor: string = window.getComputedStyle(document.body).getPropertyValue('--ion-color-primary').trim();
      this.statusBar.backgroundColorByHexString(baseColor);
    }

    if (
      (this.globalService.multicenter.fontHeaderColor && this.globalService.multicenter.fontHeaderColor.toUpperCase() === '#FFFFFF') ||
      !this.globalService.multicenter.fontHeaderColor
    ) {
      this.statusBar.styleLightContent();
    } else {
      this.statusBar.styleDefault();
    }
  }

  //#endregion
}
