import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { DataHttpService } from 'src/app/shared/services/data-http/data-http.service';
import { Member, MemberBase } from 'src/app/shared/models/members/member';
import { MemberGroup, MemberGroupBase } from 'src/app/shared/models/members/member-group';
import { MemberPersonalData, MemberAddress, MemberAuthorizedPerson } from 'src/app/shared/models/members/member-personal-data';
import { MemberAllergiesParam } from 'src/app/shared/models/members/member-allergies-param';
import { MemberAuthorizedParam } from 'src/app/shared/models/members/member-authorized-param';
import { MemberLinkedMember } from 'src/app/shared/models/members/member-linked-member';
import { MemberRegisterLinkedMemberParam } from 'src/app/shared/models/members/member-register-linked-member-param';

@Injectable({
  providedIn: 'root',
})
export class MemberService {
  constructor(private dataService: DataHttpService) {}

  /**
   * Devuelve la información del socio
   *
   * @param idMember Identificador de socio
   */
  public getMember(idMember: number): Observable<Member> {
    return this.dataService.doGet('/socios/' + idMember, 'v1.0').pipe(
      map((response: HttpResponse<any>) => {
        if (response.body) {
          return new Member({
            id: response.body.id,
            name: response.body.nombre,
            surname: response.body.apellidos,
            photo: null,
            memberNo: response.body.numero,
            identificationDocument: response.body.documentoIdentificativo,
            isMember: response.body.isSocio,
            isEmployee: response.body.isEmpleado,
            group: !response.body.grupo
              ? null
              : new MemberGroupBase({
                  id: response.body.grupo.id,
                  name: response.body.grupo.nombre,
                }),
            isPrincipal: true,
            isTutored: false,
            dateUploadPhoto: new Date(response.body.fechaModFoto),
          });
        } else {
          return null;
        }
      })
    );
  }

  /**
   * Devuelve la información del grupo del socio
   *
   * @param idMember Identificador de socio
   */
  public getMemberGroup(idMember: number): Observable<MemberGroup> {
    return this.dataService.doGet('/socios/' + idMember + '/grupo', 'v1.0').pipe(
      map((response: HttpResponse<any>) => {
        if (response.body) {
          return new MemberGroup({
            id: response.body.id,
            name: response.body.nombre,
            code: response.body.codigo,
            idMemberTitular: response.body.idSocioTitular,
            members: !response.body.miembros
              ? new Array<MemberBase>()
              : response.body.miembros.map(
                  (m: any) =>
                    new MemberBase({
                      id: m.id,
                      name: m.nombre,
                      surname: m.apellidos,
                      photo: null,
                      memberNo: m.numero,
                      identificationDocument: m.documentoIdentificativo,
                    })
                ),
          });
        } else {
          return null;
        }
      })
    );
  }

  /**
   * Devuelve los datos personales del socio
   *
   * @param idMember Identificador de socio
   */
  public getPersonalData(idMember: number): Observable<MemberPersonalData> {
    return this.dataService.doGet('/socios/' + idMember + '/datospersonales', 'v1.0').pipe(
      map((response: HttpResponse<any>) => {
        if (response.body) {
          return new MemberPersonalData({
            id: response.body.id,
            name: response.body.nombre,
            surname: response.body.apellidos,
            document: response.body.documento,
            typeDocument: response.body.tipoDocumento,
            birthdate: response.body.fechaNacimiento,
            sex: response.body.sexo,
            email: response.body.email,
            mobilePhone: response.body.movil,
            landline: response.body.telefono,
            address: new MemberAddress({
              address: response.body.direccion.direccion,
              province: response.body.direccion.provincia,
              locality: response.body.direccion.localidad,
              country: response.body.direccion.pais,
              zipcode: response.body.direccion.cp,
            }),
            allergies: response.body.alergias,
            memberAuthorizedPeople: !response.body.personasAutorizadas
              ? new Array<MemberAuthorizedPerson>()
              : response.body.personasAutorizadas.map(
                  (p: any) =>
                    new MemberAuthorizedPerson({
                      id: p.id,
                      name: p.nombre,
                      surname: p.apellidos,
                      document: p.documento,
                      typeDocument: p.tipoDocumento,
                      email: p.email,
                      mobilePhone: p.telefono,
                    })
                ),
          });
        } else {
          return null;
        }
      })
    );
  }

  /**
   * Actualiza las alergias de un socio
   *
   * @param idMember Identificador de socio
   * @param memberAllergiesParam Parámetros de alergias
   */

  public setAllergies(idMember: number, memberAllergiesParam: MemberAllergiesParam): Observable<boolean> {
    return this.dataService.doPost('/socios/' + idMember + '/alergias', 'v1.0', memberAllergiesParam).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 200) {
          return true;
        }
      })
    );
  }

  /**
   * Crea un autorizado de un socio
   *
   * @param idMember Identificador de socio
   * @param memberAuthorizedParam Parámetros de autorizado
   */

  public createAuthorized(idMember: number, memberAuthorizedParam: MemberAuthorizedParam): Observable<boolean> {
    return this.dataService.doPost('/socios/' + idMember + '/autorizados', 'v1.0', memberAuthorizedParam).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 200) {
          return true;
        }
      })
    );
  }

  /**
   * Actualiza un autorizado de un socio
   *
   * @param idMember Identificador de socio
   * @param memberAuthorizedParam Parámetros de autorizado
   */

  public updateAuthorized(idMember: number, idAuthorized: number, memberAuthorizedParam: MemberAuthorizedParam): Observable<boolean> {
    return this.dataService.doPut('/socios/' + idMember + '/autorizados/' + idAuthorized, 'v1.0', memberAuthorizedParam).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 200) {
          return true;
        }
      })
    );
  }

  /**
   * Elimina un autorizado de un socio
   *
   * @param idMember Identificador de socio
   * @param idAuthorized Identificador de autorizado
   */

  public deleteAuthorized(idMember: number, idAuthorized: number): Observable<boolean> {
    return this.dataService.doDelete('/socios/' + idMember + '/autorizados/' + idAuthorized, 'v1.0').pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 200) {
          return true;
        }
      })
    );
  }

  /**
   * Obtiene los usuarios vinculados en otros centros de un usuario
   *
   * @param idUser Identificador de usuario
   */

  public getLinkedMembers(idUser: number): Observable<MemberLinkedMember[]> {
    return this.dataService.doGet('/usuarios/' + idUser + '/vinculados', 'v1.0').pipe(
      map((response: HttpResponse<any>) => {
        return response.body.map((linkedMember: any) => {
          return new MemberLinkedMember({
            idCenter: linkedMember.idCentro,
            centerName: linkedMember.nombreCentro,
            idMember: linkedMember.idSocio,
            principal: linkedMember.principal,
          });
        });
      })
    );
  }

  /**
   * Crea una ficha de socio a un usuario en un centro vinculado
   *
   * @param idUser Identificador de socio
   * @param memberAuthorizedParam Parámetros de autorizado
   */

  public createLinkedMember(idUser: number, memberRegisterLinkedMemberParam: MemberRegisterLinkedMemberParam): Observable<number> {
    return this.dataService.doPost('/usuarios/' + idUser + '/vinculados', 'v1.0', memberRegisterLinkedMemberParam).pipe(
      map((response: HttpResponse<any>) => {
        return response.body;
      })
    );
  }

  /**
   * Devuelve los socios que coincidan con una cadena de búsqueda
   *
   * @param idCenter Identificador de centro.
   * @param searchString Cadena de búsqueda.
   */
  public getMembersSearch(idCenter: number, searchString: string): Observable<MemberBase[]> {
    let params: HttpParams = new HttpParams();
    params = params.append('cadenaBusqueda', searchString);

    return this.dataService.doGet('/centros/' + idCenter + '/socios/search', 'v1.0', params).pipe(
      map((response: HttpResponse<any>) => {
        return response.body.map((mem: any) => {
          return new MemberBase({
            id: mem.id,
            name: mem.nombre,
            surname: mem.apellidos,
            photo: null,
            memberNo: mem.numero,
            identificationDocument: mem.documentoIdentificativo,
          });
        });
      })
    );
  }

  /**
   * Obtiene la imagen de un socio
   *
   * @param idMember Identificador de socio
   */
  public getMemberImage(idMember: number): Observable<any> {
    return this.dataService.doGetDoc('/socios/' + idMember + '/imagen', 'v1.0').pipe(
      map((response: HttpResponse<any>) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(new Blob([response.body], { type: response.body.type }));
          reader.onloadend = (): void => resolve(reader.result);
        });
      }),
      catchError(() => {
        return of(null);
      })
    );
  }
}
