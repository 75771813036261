import { PaymentMechanism } from './payment-mechanism'
import { BalanceBonusBase } from 'src/app/shared/models/hirings/bonus/balance-bonus';
import { ReserveFee } from '../fees/reserve-fee';
import { CollectiveZonesFee } from 'src/app/shared/models/fees/collective-zones-fee';
import { BonusBase } from '../hirings/bonus/bonus';
import { MultibonusBase } from '../hirings/bonus/multibonus-base';

export class PendingResourcePaymentMechanisms {
  public pendingPayment: boolean;
  public paymentMechanisms: PaymentMechanism[];
  public bonuses: BonusBase[];
  public fees: ReserveFee[];
  public collectiveZonesFees: CollectiveZonesFee[];
  public balanceBonus: BalanceBonusBase;
  public multibonuses: MultibonusBase[];

  public constructor(fields?: {
    pendingPayment: boolean;
    paymentMechanisms: PaymentMechanism[];
    bonuses: BonusBase[];
    fees: ReserveFee[];
    collectiveZonesFees: CollectiveZonesFee[];
    balanceBonus: BalanceBonusBase;
    multibonuses: MultibonusBase[];
  }) {
    if (fields) {
      Object.assign(this, fields);
    }
  }
}
